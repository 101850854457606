<template>
  <div>
    <div>
      <h1 class="white--text text-center ma-5">Rebates</h1>
    </div>
    <v-container grid-list-xl>
      <v-layout row wrap justify-center v-if="isAlreadyLoaded && promotions.length > 0">
        <v-flex xs12 sm6 md4 xl3 v-for="promotion in promotions" :key="promotion.id">
          <CeurCard :promotion="promotion.promotion" />
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-center v-if="isAlreadyLoaded && promotions.length == 0">
        <div class="white--text text-center">
          <p class="mb-5"></p>
          <h2>{{ noPromotionsMessage }}</h2>
          <GoogleReCaptcha :showLegend="false"></GoogleReCaptcha>
          <p class="mb-5"></p>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import CeurCard from "./CeurCard.vue";
import GoogleReCaptcha from "../../gapp-components/components/display/GoogleReCaptcha.vue";
import Vue from "vue";

import { mapGetters } from "vuex";

export default {
  name: "CeurList",
  metaInfo() {
    return {
      title: this.$i18n.translate("List of End User Rebates")
    };
  },
  components: {
    CeurCard,
    GoogleReCaptcha
  },
  data() {
    return {
      env: null,
      loading: false,
      isAlreadyLoaded: false,
      noPromotionsMessage: this.$i18n.translate("Check back with us soon for new rebates!"),
      promotions: [],
      headers: [
        {
          value: "product.productKey",
          text: "Model",
          sortable: false
        },
        {
          value: "product.name",
          text: "Product",
          sortable: false
        }
      ],
      expandedProducts: []
    };
  },
  watch: {
    selectedCountry() {
      this.getData();
      setTimeout(() => {
        this.noPromotionsMessage = this.$i18n.translate("Check back with us soon for new promotions!");
      }, 300);
    },
    selectedLocale() {
      setTimeout(() => {
        this.noPromotionsMessage = this.$i18n.translate("Check back with us soon for new promotions!");
      }, 300);
    }
  },

  methods: {
    getData() {
      this.loading = true;

      let filters = {
        country: { name: this.selectedCountry.name },
        isSubmissionsEffective: true,
        invitationOnly: false
      };

      this.$api
        .post("/api/promotions/search/eligible/public?size=100", filters)
        .then(({ data }) => {
          this.promotions = data.content;
          this.promotions.reduce((p, promotion) => this.getUploads(promotion.promotion), Promise.resolve());
          this.isAlreadyLoaded = true;
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getUploads(promotion) {
      return this.$api
        .get("/api/promotions/" + promotion.id + "/uploads")
        .then(({ data }) => {
          Vue.set(promotion, "uploads", data._embedded.uploads);
          data._embedded.uploads.forEach(upload => {
            if (upload.contentType.startsWith("image")) {
              Vue.set(promotion, "banner", upload);
            } else if (upload.contentType.startsWith("application")) {
              Vue.set(promotion, "download", upload);
            }
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getProducts(promotion) {
      if (promotion.id in this.expandedProducts) {
        return;
      }
      this.loading = true;
      return this.$api
        .get("/api/promotions/" + promotion.id + "/public")
        .then(({ data }) => {
          this.expandedProducts[promotion.id] = data.promotionProducts;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  created() {
    this.env = process.env;
  },
  mounted() {
    this.getData();
    this.headers = this.headers.forEach(h => {
      h.text = this.$i18n.translate(h.text);
    });
  },
  computed: {
    ...mapGetters(["selectedLocale", "selectedCountry"])
  }
};
</script>
